<template>
  <!-- 阿里巴巴套头 -->
    <div v-if="$platform==='alibaba'">
        <div class="alibaba" @click="jump" style="cursor:pointer;">
            <div style="">
                <a href="https://fuwu.1688.com/qn/index.html">
                    <img :src="LOGO" alt="">
                </a>
            </div>
            <div class="split_line"></div>
            <div class="logo">
                <div style="font-size:24px;">
                    店铺总管
                </div>
                <div style="font-size:12px;color:#999;margin-top:3px;">
                    www.dpzog.com
                </div>
            </div>
            <div class="welcome">
                欢迎您 <a href="javascript:void(0)" style="color:#ff7300;">{{userInfo?userInfo.username:'未登录'}}</a> 退出
            </div>
        </div>

        <div class="alibaba_right">
            <a target="_blank" style="position:relative;top:2px;" href="//amos.alicdn.com/msg.aw?v=2&uid=%E4%B8%9C%E5%9D%A1%E7%BA%B8%E4%B8%9A&site=cnalichn&s=11&charset=gbk" >
            <img border="0" src="//amos.alicdn.com/online.aw?v=2&uid=%E4%B8%9C%E5%9D%A1%E7%BA%B8%E4%B8%9A&site=cnalichn&s=11&charset=UTF-8" alt="周一到周日 9:00-22:00" />
            <span style="color:#1266c9; vertical-align:top">东坡纸业</span></a>
            <a href="javascript:void(0)" @click="showPhone">联系方式</a>
            <a href="https://www.yuque.com/books/share/f462738d-48a0-47ab-a437-56580c99be80?#" target="_blank">操作指南</a>
            <a href="https://fuwu.1688.com/qn/index.html" >返回市场</a>
            <div class="split_line2"></div>
            <a href="https://fuwu.1688.com/qn/index.html">剩余服务时间 <span style="color:#ff7300;"> 14 </span> 天</a>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            userInfo:{},
            showGuideFlag:false,
            LOGO:require('../../assets/img/home/logo.png')
        }
    },
    methods:{
        showPhone(){
            this.$alert('联系方式：18033030302   周一到周六 9:00-18:30');
        },
        showGuide(){
            this.showGuideFlag = true;
        },
        jump(){
            location.href = 'https://fuwu.1688.com/qn/index.html';
        }
    },
    created(){
        this.userInfo = JSON.parse(localStorage.getItem('userinfo'));
    }
}
</script>

<style scoped lang="less">
    .alibaba{
        height:40px;display: flex;
        .logo{
            display: inline-block;
        }
        img{
            height:36px;
        }
        .welcome{
            margin-left:100px;position:relative;top:8px;

        }
        .split_line{
            height: 36px;
            width: 1px;
            background: #C4C4C4;
            display: inline-block;
            vertical-align: middle;
            margin-left: 3px;
            margin-right: 10px;
        }
    }
    .alibaba_right{
        width:500px;position:absolute;right:10%;top:10px;
        a{
            display: inline-block;margin-right:20px;
        }
        .split_line2{
            height: 20px;
            width: 1px;
            background: #C4C4C4;
            display: inline-block;
            vertical-align: middle;
            margin-left: 3px;
            margin-right: 10px;
        }

    }
</style>