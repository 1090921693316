<template>
    <div>
    </div>
</template>
<script>
export default {
    mounted(){
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = 'http://g.tbcdn.cn/sj/securesdk/0.0.3/securesdk_v2.js';
        s.setAttribute('data-appkey','23104858');
        document.body.appendChild(s);
    }
}
</script>

<style>

</style>